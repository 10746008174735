import React, { useState } from "react";
import { DialogPanel, DialogTitle, TransitionChild } from "@headlessui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import PrimaryButton from "../atoms/PrimaryButton";
import { NavLink } from "react-router-dom";
import LoginFormSvg from "../atoms/LoginFormSvg";
import CommonModal from "./CommonModal";
import { useDispatch } from "react-redux";
import {
  closeModal,
  openModal,
  setLoading,
} from "../../redux/reducers/uiReducer";
import InputField from "../atoms/InputField";
import { useAuth } from "../../providers/AuthContext";
import setFormErrors from "../../helpers/setFormErrors";
import { REGEX } from "../../constants/RegexConstants";

export interface IRegisterFormInput {
  email: string;
  password: string;
  password_confirmation: string;
  name: string;
  phone_number: number;
  phone_number_country_code: string;
}

interface RegisterModalProps {}

const RegisterModal: React.FC<RegisterModalProps> = () => {
  const dispatch = useDispatch();
  const { register: signup } = useAuth();
  const [btnLoading, setBtnLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm<IRegisterFormInput>();

  const onSubmit: SubmitHandler<IRegisterFormInput> = (data) => {
    setBtnLoading(true);
    signup(
      { ...data, phone_number_country_code: "+91" },
      (message) => {
        if (typeof message === "string") {
        } else {
          setFormErrors<IRegisterFormInput>(message, setError);
        }
      },
      () => {
        reset();
      }
    ).finally(() => {
      setBtnLoading(false);
    });
  };

  return (
    <CommonModal name="RegisterModal">
      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 md:p-0">
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel className="rounded-2xl bg-white text-left shadow-xl transition-all md:my-8 md:w-fit justify-center items-center flex flex-col">
              <div className="flex items-center justify-center columns-2">
                <div className="hidden md:block w-full ">
                  <LoginFormSvg className="md:h-[250px] w-full" />
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                  <div className="text-start md:mt-0 md:text-left pt-4">
                    <DialogTitle
                      as="h3"
                      className="text-xl text-center font-semibold leading-6 text-gray-900"
                    >
                      Register
                    </DialogTitle>
                    <div className="mt-2">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col justify-center items-start gap-4 p-4 md:px-4 max-w-80"
                      >
                        <InputField
                          placeholder="Enter your name"
                          type="text"
                          error={errors?.name?.message}
                          register={register("name", {
                            // required: "This Field is Required",
                            pattern: {
                              value: REGEX.onlyAlphabets,
                              message: "only alphabets allow",
                            },
                          })}
                        />
                        <InputField
                          placeholder="Email"
                          type="text"
                          error={errors?.email?.message}
                          register={register("email", {
                            // required: "This Field is Required",
                            pattern: {
                              value: REGEX.email,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        <InputField
                          placeholder="Phone Number"
                          type="text"
                          error={errors?.phone_number?.message}
                          register={register("phone_number", {
                            // required: "This Field is Required",
                            pattern: {
                              value: REGEX.phoneNumber,
                              message: "Invalid Phone Number",
                            },
                          })}
                        />
                        <InputField
                          placeholder="Password"
                          type="password"
                          error={errors?.password?.message}
                          register={register("password", {
                            // required: "This Field is Required",
                            pattern: {
                              value: REGEX.password,
                              message:
                                "Please use a strong password (Minimum 8 Characters, Use atleast one capital letter, Use small letter, Use number and Use Special character)",
                            },
                          })}
                        />
                        <InputField
                          placeholder="Confirm Password"
                          type="password"
                          error={errors?.password_confirmation?.message}
                          register={register("password_confirmation", {
                            // required: "This Field is Required",
                            validate: (val: string) => {
                              if (watch("password") != val) {
                                return "Your passwords is not matching";
                              }
                            },
                          })}
                        />
                        <PrimaryButton
                          loading={btnLoading}
                          type="submit"
                          btnClassName="w-full text-center flex items-center justify-center rounded-[6px] h-[38px]"
                        >
                          Sign Up
                        </PrimaryButton>
                      </form>
                    </div>
                  </div>
                  <div className="pb-4">
                    <p className="text-xs">
                      Already have an account?{" "}
                      <span
                        className="text-orange-500 hover:underline cursor-pointer"
                        onClick={() => {
                          dispatch(closeModal("RegisterModal"));
                          dispatch(openModal("LoginModal"));
                        }}
                      >
                        Login
                      </span>
                    </p>
                    <p className="text-[8px] text-center">
                      <NavLink
                        to="terms-and-conditions"
                        target="_blank"
                        className="hover:underline cursor-pointer"
                      >
                        Terms & Conditions
                      </NavLink>{" "}
                      and{" "}
                      <NavLink
                        to="privacy-policy"
                        target="_blank"
                        className="hover:underline cursor-pointer"
                      >
                        Privacy Policy
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </CommonModal>
  );
};

export default RegisterModal;
