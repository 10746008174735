import { IResponse, useAuth } from "../../providers/AuthContext";
import {
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  GlobeAltIcon,
  PlusCircleIcon,
  ShoppingCartIcon,
  TicketIcon,
  TruckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import useCheckoutOrder from "../../redux/hooks/useCheckoutOrder";
import { ReactNode, useEffect, useState } from "react";
import api from "../../helpers/api";
import {
  IAddress,
  ICoupon,
  IOrder,
  IPlan,
  IUserAddress,
} from "../../types/CommonType";
import {
  DurationOfRecharges,
  NumberOfLicences,
} from "../../constants/ConstantData";
import SelectInputField from "../../components/atoms/SelectInputField";
import PrimaryButton from "../../components/atoms/PrimaryButton";
import { useDispatch } from "react-redux";
import { setCheckoutOrder } from "../../redux/reducers/checkoutReducer";
import toast from "react-hot-toast";
import {
  openModal,
  setLoading,
  startRefetch,
  stopRefetch,
} from "../../redux/reducers/uiReducer";
import { setFormMode } from "../../redux/reducers/formModeReducer";
import useUI from "../../redux/hooks/useUi";

interface CheckoutProps {}

const Checkout: React.FC<CheckoutProps> = () => {
  const { auth, selectedCompany, setNewCompany } = useAuth();
  const { order } = useCheckoutOrder();
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [addresses, setAddresses] = useState<IUserAddress[]>([]);
  const dispatch = useDispatch();
  const { refetch } = useUI();
  const [couponCode, setCouponCode] = useState<{
    code: string;
    error?: string;
  }>();
  const [btnLoading, setBtnLoading] = useState(false);
  const [checkOutBtnLoading, setCheckOutBtnLoading] = useState(false);

  const licenseCost: number = Number(
    process.env.REACT_APP_LICENSE_COST ?? "800"
  );
  const gstRate: number = Number(process.env.REACT_APP_GST_RATE ?? "18");

  const instruction = [
    "Orders above ₹10 lakhs are not allowed, Order in segments if it exceeds over it",
    "If Order is not fullfilled during checkout, Please check in Orders page",
  ];

  useEffect(() => {
    api
      .GET<IPlan[]>(`/plans`)
      .then((response) => {
        if (response?.data) {
          setPlans(response?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }, []);

  const fetchAddresses = () => {
    api
      .GET<IResponse<IUserAddress[]>>(`/user-addresses`, {
        populate: JSON.stringify([
          "pincode",
          "user",
          "city",
          "state",
          "country",
        ]),
      })
      .then((response) => {
        const adsData = response?.data?.data;
        if (adsData) {
          setAddresses(adsData);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(stopRefetch("AddressList"));
      });
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  useEffect(() => {
    if (refetch.includes("AddressList")) {
      fetchAddresses();
    }
  }, [refetch]);

  const RenderSection = ({
    index,
    title,
    children,
  }: {
    index: string;
    title: string;
    children: ReactNode;
  }) => {
    return (
      <li className="mb-10 ms-6">
        <div className="absolute -start-3 rounded-full bg-geovetraGreen-500 p-2 flex items-center justify-center w-6 h-6">
          <span className="text-white text-sm">{index}</span>
        </div>
        <p className="">{title}</p>
        <div className="w-full py-4 pl-0 md:pr-5">{children}</div>
      </li>
    );
  };

  const getNetTotalAmount = () => {
    return (
      licenseCost * order?.number_of_licenses +
      (order?.plan?.cost_per_day ?? 0) *
        (order?.number_of_days ?? 0) *
        (order?.number_of_licenses ?? 0)
    );
  };

  const getDiscountedAmount = () => {
    return (
      getNetTotalAmount() *
      (order?.coupon?.discount_percentage
        ? order?.coupon?.discount_percentage / 100
        : 0)
    );
  };

  const getFinalTotal = () => {
    const discountAmount = getNetTotalAmount() - getDiscountedAmount();
    const taxAmount = discountAmount * (gstRate / 100);
    return taxAmount + discountAmount;
  };

  const handleVerifyCouponCode = () => {
    setBtnLoading(true);
    api
      .GET<IResponse<ICoupon>>(
        `${selectedCompany?.domain}/orders/apply-coupon/${couponCode?.code}`
      )
      .then((response) => {
        if (response?.data?.data) {
          dispatch(
            setCheckoutOrder({ ...order, coupon: response?.data?.data })
          );
        } else {
          setCouponCode({
            ...couponCode,
            error: response?.data?.message,
          } as any);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const handleCheckoutOrder = () => {
    dispatch(setLoading({ isLoading: true, label: "Redirecting..." }));
    setCheckOutBtnLoading(true);
    api
      .POST<IResponse<IOrder>>(`${selectedCompany?.domain}/orders`, {
        ...order,
        number_of_devices: 0,
      })
      .then((response) => {
        if (response?.data?.data) {
          api
            .POST(
              `/${selectedCompany?.domain}/orders/${response?.data?.data?.id}/pay`
            )
            .then((res: any) => {
              if (res?.data?.redirect) window.location.href = res.data.redirect;
            });
        } else {
          toast.error(response?.data?.message ?? "");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        dispatch(setLoading({ isLoading: false }));
        setCheckOutBtnLoading(false);
      });
  };

  return (
    <div className="w-full md:max-w-7xl">
      <p className="text-gray-600 font-semibold text-base mb-4 pt-4 px-4">
        Order Checkout
      </p>
      <div className="md:grid md:grid-cols-6 md:gap-2">
        {/* Left Side */}
        <div className="p-4 col-span-4">
          <ol className="relative border-s-2 border-dashed border-geovetraGreen-400">
            {/* Select Company */}
            <RenderSection index="1" title="Select Company">
              <div className="flex gap-4 items-center overflow-x-auto bg-[#F9FAFB] shadow-md rounded-xl p-4">
                {auth?.user_companies?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex flex-col p-4 bg-white rounded-xl cursor-pointer shadow hover:bg-gray-200 w-full max-w-60 min-w-60 ${
                        item?.company?.domain === selectedCompany?.domain
                          ? "outline outline-geovetraGreen-500 outline-2 outline-offset-2"
                          : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setNewCompany(item?.company);
                      }}
                    >
                      <div className="flex items-center justify-between gap-2 mb-2">
                        {item?.company?.logo ? (
                          <img
                            src={item?.company?.logo}
                            className="size-8 md:size-12 rounded-2xl border border-gray-400"
                          />
                        ) : (
                          <div className="size-8 md:size-12 rounded-2xl bg-gray-200 flex items-center justify-center">
                            <BuildingOffice2Icon className="size-5 md:size-6 text-black" />
                          </div>
                        )}

                        <div className="flex flex-col justify-between items-start flex-1">
                          <p className="text-base md:text-lg font-semibold line-clamp-1">
                            {item?.company?.name}
                          </p>
                          <p className="bg-red-100 p-1 px-2 rounded-xl w-fit text-[10px] text-red-500 font-medium line-clamp-1">
                            {item?.company?.gstin}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <input
                            defaultChecked={
                              item?.company?.domain === selectedCompany?.domain
                            }
                            type="radio"
                            className="w-4 h-4 text-blue-600"
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   e.stopPropagation();
                            //   setNewCompany(item?.company);
                            // }}
                          />
                        </div>
                      </div>
                      <div
                        className="flex text-xs justify-between items-baseline my-2 mt-4"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <a
                          href={`https://${item?.company?.domain}.geovetra.com`}
                          target="_blank"
                          className="underline text-start text-blue-500 font-semibold hover:text-blue-700"
                        >
                          {item?.company?.domain}
                        </a>
                        {item?.role && (
                          <p className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-2xl border border-green-700">
                            {item?.role as any}
                          </p>
                        )}
                      </div>
                      <div className="flex text-xs justify-between items-center">
                        <p>{item?.company?.type_of_company}</p>
                        <p className="text-end">
                          {item?.company?.created_at?.date}
                        </p>
                      </div>
                    </div>
                  );
                })}{" "}
                {auth?.user_companies && auth?.user_companies?.length <= 5 && (
                  <div
                    className={`flex bg-white flex-col items-center border-dashed border-2 min-w-screen gap-6 rounded-xl p-9 cursor-pointer hover:bg-gray-100 max-w-60 min-w-60 text-center`}
                    onClick={() => {
                      dispatch(setFormMode("create"));
                      dispatch(openModal("OrganizationModal"));
                    }}
                  >
                    <PlusCircleIcon className="size-8 text-gray-400" />
                    <p className="text-base text-gray-400">
                      Create Organization
                    </p>
                  </div>
                )}
              </div>
            </RenderSection>

            {/* Select Plan */}

            <RenderSection index="2" title="Select Plan">
              <div className="flex gap-4 items-center overflow-x-auto bg-[#F9FAFB] shadow-md rounded-xl p-4">
                {plans?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`p-4 min-w-60 w-full bg-white rounded-xl cursor-pointer shadow ${
                        item?.id == order?.plan?.id
                          ? "outline outline-geovetraGreen-500 outline-2 outline-offset-2"
                          : ""
                      }`}
                      onClick={() => {
                        dispatch(
                          setCheckoutOrder({
                            ...order,
                            plan: item,
                          })
                        );
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <p className="text-base md:text-lg font-semibold line-clamp-1 capitalize">
                          {item?.title}
                        </p>
                        <div className="flex items-center">
                          <input
                            defaultChecked={item?.id == order?.plan?.id}
                            type="radio"
                            className="w-4 h-4 text-blue-600"
                          />
                        </div>
                      </div>
                      <p className="text-sm">
                        <span className="font-semibold">
                          Deliveries Per Day:
                        </span>{" "}
                        {item?.deliveries_per_day}
                      </p>
                      <p className="text-sm">
                        <span className="font-semibold">Cost Per Day:</span> ₹
                        {item?.cost_per_day}
                      </p>
                    </div>
                  );
                })}
              </div>
            </RenderSection>

            {/* Select Licenses and Recharges */}
            {order?.plan?.id && (
              <RenderSection index="3" title="Select Licenses and Recharges">
                <div className="flex md:items-center items-end justify-evenly gap-5 bg-[#F9FAFB] shadow-md rounded-xl p-4">
                  <SelectInputField
                    data={NumberOfLicences}
                    label="Number of Licenses"
                    defaultValue={order?.number_of_licenses?.toString()}
                    onChange={(e) => {
                      dispatch(
                        setCheckoutOrder({
                          ...order,
                          number_of_licenses: Number(e?.target?.value) ?? 0,
                        })
                      );
                    }}
                    placeholder="Select number of licenses"
                    SelectInputClassname="w-full"
                  />
                  <SelectInputField
                    data={DurationOfRecharges}
                    label="Recharge Duration in Days"
                    defaultValue={order?.number_of_days?.toString()}
                    onChange={(e) => {
                      dispatch(
                        setCheckoutOrder({
                          ...order,
                          number_of_days: Number(e?.target?.value) ?? 0,
                        })
                      );
                    }}
                    placeholder="Select recharge duration in days"
                    SelectInputClassname="w-full"
                  />
                </div>
              </RenderSection>
            )}

            {/* Select billing Address */}
            {order?.number_of_days && order?.number_of_licenses && (
              <RenderSection index="4" title="Select Billing Address">
                <div className="flex gap-4 items-center overflow-x-auto bg-[#F9FAFB] shadow-md rounded-xl p-4">
                  {addresses &&
                    addresses?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex bg-white flex-col items-start border min-w-screen gap-4 rounded-xl p-6 cursor-default min-w-60 max-w-60 ${
                            item?.id === order?.billing_address_details?.id
                              ? "outline outline-geovetraGreen-500 outline-2 outline-offset-2"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(
                              setCheckoutOrder({
                                ...order,
                                billing_address_details: item,
                              })
                            );
                          }}
                        >
                          <div className="flex justify-between items-center w-full gap-1">
                            <div className="p-2 rounded-xl bg-red-100">
                              <BuildingStorefrontIcon className="size-6 text-red-600" />
                            </div>
                            <div className="flex justify-between items-center gap-3">
                              <div className="flex flex-col justify-end items-start">
                                <p className="text-sm mb-1 font-semibold line-clamp-1">
                                  {auth?.user?.name}
                                </p>
                                <div className="bg-blue-100 p-1 px-2 rounded-xl w-fit">
                                  <p className="text-xs text-end text-blue-600 font-medium">
                                    {auth?.user?.phone_number}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  defaultChecked={
                                    item?.id ===
                                    order?.billing_address_details?.id
                                  }
                                  type="radio"
                                  className="w-4 h-4 text-blue-600"
                                />
                              </div>
                            </div>
                          </div>
                          <p
                            className={`text-xs font-semibold text-gray-500 line-clamp-2 flex-1`}
                          >
                            {item.address_line_1}{" "}
                            {item.address_line_2
                              ? `, ${item.address_line_2} `
                              : ""}
                            {item.city?.name ? `, ${item.city?.name}` : ""}
                            {item.state?.name ? `, ${item.state?.name}` : ""}
                            {item.country?.name
                              ? `, ${item.country?.name}`
                              : ""}
                            {item.pincode?.name
                              ? `, ${item.pincode?.name}`
                              : ""}
                          </p>
                        </div>
                      );
                    })}
                  <div
                    className="min-w-screen md:max-w-72 flex bg-white flex-col items-center border-dashed border-2 min-w-screen gap-4 rounded-xl p-8 cursor-pointer hover:bg-gray-100 max-w-60 min-w-60"
                    onClick={() => {
                      dispatch(setFormMode("create"));
                      dispatch(openModal("AddressModal"));
                    }}
                  >
                    <PlusCircleIcon className="size-8 text-gray-400" />
                    <p className="text-base text-gray-400">
                      Create New Address
                    </p>
                  </div>
                </div>
              </RenderSection>
            )}

            {/* Select shipping Address */}
            {order?.billing_address_details && (
              <RenderSection index="5" title="Select Shipping Address">
                <div className="flex gap-4 items-center overflow-x-auto bg-[#F9FAFB] shadow-md rounded-xl p-4">
                  {addresses &&
                    addresses?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex bg-white flex-col items-start border min-w-screen gap-4 rounded-xl p-6 cursor-default max-w-60 min-w-60 ${
                            item?.id === order?.shipping_address_details?.id
                              ? "outline outline-geovetraGreen-500 outline-2 outline-offset-2"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(
                              setCheckoutOrder({
                                ...order,
                                shipping_address_details: item,
                              })
                            );
                          }}
                        >
                          <div className="flex justify-between items-center w-full gap-1">
                            <div className="p-2 rounded-xl bg-red-100">
                              <BuildingStorefrontIcon className="size-6 text-red-600" />
                            </div>
                            <div className="flex items-center justify-between gap-3">
                              <div className="flex flex-col justify-center items-start">
                                <p className="text-sm mb-1 font-semibold line-clamp-1">
                                  {auth?.user?.name}
                                </p>
                                <div className="bg-blue-100 p-1 px-2 rounded-xl w-fit">
                                  <p className="text-xs text-blue-600 font-medium">
                                    {auth?.user?.phone_number}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  defaultChecked={
                                    item?.id ===
                                    order?.shipping_address_details?.id
                                  }
                                  type="radio"
                                  className="w-4 h-4 text-blue-600"
                                  onClick={() => {}}
                                />
                              </div>
                            </div>
                          </div>
                          <p
                            className={`text-xs font-semibold text-gray-500 line-clamp-2 flex-1`}
                          >
                            {item.address_line_1}{" "}
                            {item.address_line_2
                              ? `, ${item.address_line_2} `
                              : ""}
                            {item.city?.name ? `, ${item.city?.name}` : ""}
                            {item.state?.name ? `, ${item.state?.name}` : ""}
                            {item.country?.name
                              ? `, ${item.country?.name}`
                              : ""}
                            {item.pincode?.name
                              ? `, ${item.pincode?.name}`
                              : ""}
                          </p>
                        </div>
                      );
                    })}
                  <div
                    className="flex bg-white flex-col items-center border-dashed border-2 min-w-screen gap-4 rounded-xl p-8 cursor-pointer hover:bg-gray-100 max-w-60 min-w-60"
                    onClick={() => {
                      dispatch(setFormMode("create"));
                      dispatch(openModal("AddressModal"));
                    }}
                  >
                    <PlusCircleIcon className="size-8 text-gray-400" />
                    <p className="text-base text-gray-400">
                      Create New Address
                    </p>
                  </div>
                </div>
              </RenderSection>
            )}
          </ol>
        </div>

        {/* Right Side */}
        <div className="p-4 md:col-span-2">
          <p className="mb-4">Summary</p>
          <div className="bg-[#F9FAFB] shadow-md p-4 rounded-xl">
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-1">
                <p className="flex items-center gap-1 text-base md:text-lg font-semibold line-clamp-1">
                  <BuildingOffice2Icon className="size-5 text-black" />
                  {selectedCompany?.name}
                </p>
                <div className="flex text-xs justify-between items-baseline">
                  <a
                    href={`https://${selectedCompany?.domain}.geovetra.com`}
                    target="_blank"
                    className="flex items-center gap-1 underline text-start text-blue-500 font-semibold hover:text-blue-700"
                  >
                    <GlobeAltIcon className="size-5" />{" "}
                    {selectedCompany?.domain}
                  </a>
                </div>
              </div>
              <div className="flex flex-col items-end gap-2">
                <p className="bg-red-100 p-1 px-2 rounded-xl w-fit text-[10px] text-red-500 font-medium line-clamp-1">
                  {selectedCompany?.gstin}
                </p>
                <p className="text-xs">{selectedCompany?.type_of_company}</p>
              </div>
            </div>
            <hr className="my-4" />
            <div className="flex items-center justify-between">
              <p className="flex items-center gap-1 text-base md:text-lg font-semibold line-clamp-1">
                <ShoppingCartIcon className="size-5 text-black" />
                Plan:
              </p>
              <p className="flex items-center gap-1 text-base md:text-lg font-semibold line-clamp-1">
                <p className="bg-green-100 p-1 px-2 rounded-xl w-fit text-xs text-green-500 capitalize ">
                  {order?.plan?.title}
                </p>
              </p>
            </div>
            <hr className="my-4" />{" "}
            <div className="mb-4">
              <p className="flex items-center gap-1 text-sm md:text-base font-semibold line-clamp-1">
                <TicketIcon className="size-5 text-black" />
                Billing Address
              </p>
              {order?.billing_address_details ? (
                <p className="text-xs capitalize">
                  {[
                    order?.billing_address_details?.address_line_1,
                    order?.billing_address_details?.address_line_2,
                    order?.billing_address_details?.city?.name,
                    order?.billing_address_details?.state?.name,
                    order?.billing_address_details?.country?.name,
                    order?.billing_address_details?.pincode?.name,
                  ].join(", ")}
                </p>
              ) : (
                <small>NA</small>
              )}
            </div>
            <div className="">
              <p className="flex items-center gap-1 text-sm md:text-base font-semibold line-clamp-1">
                <TruckIcon className="size-5 text-black" />
                Shipping Address
              </p>
              {order?.shipping_address_details ? (
                <p className="text-xs capitalize">
                  {[
                    order?.shipping_address_details?.address_line_1,
                    order?.shipping_address_details?.address_line_2,
                    order?.shipping_address_details?.city?.name,
                    order?.shipping_address_details?.state?.name,
                    order?.shipping_address_details?.country?.name,
                    order?.shipping_address_details?.pincode?.name,
                  ].join(", ")}
                </p>
              ) : (
                <small>NA</small>
              )}
            </div>
            <hr className="my-4" />{" "}
            {order?.number_of_licenses > 0 && (
              <>
                <div className="flex items-center justify-between mb-1">
                  <p className="md:text-sm text-xs">{`License Cost: ₹ ${licenseCost} x ${order?.number_of_licenses} Unit`}</p>
                  <p className="text-sm font-medium">
                    ₹{" "}
                    {(licenseCost * order?.number_of_licenses).toLocaleString(
                      "en-IN",
                      { minimumFractionDigits: 2 }
                    )}
                  </p>
                </div>
                <div className="flex items-center justify-between mb-1">
                  <p className="md:text-sm text-xs">Recharge Cost:</p>
                  <p className="text-sm font-medium">
                    ₹{" "}
                    {(
                      (order?.plan?.cost_per_day ?? 0) *
                      (order?.number_of_days ?? 0) *
                      (order?.number_of_licenses ?? 0)
                    ).toLocaleString("en-IN", { minimumFractionDigits: 2 })}
                  </p>
                </div>
                <div className="flex items-center justify-between mb-1">
                  <p className="md:text-sm text-xs">
                    Coupon Discount ({order?.coupon?.discount_percentage ?? 0}
                    %):
                  </p>
                  <p className="text-sm font-medium text-red-500">
                    - ₹{" "}
                    {getDiscountedAmount().toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="flex items-center justify-between mb-1">
                  <p className="md:text-sm text-xs">GST ({gstRate}%):</p>
                  <p className="text-sm font-medium">
                    ₹{" "}
                    {(
                      (getNetTotalAmount() - getDiscountedAmount()) *
                      (gstRate / 100)
                    ).toLocaleString("en-IN", { minimumFractionDigits: 2 })}
                  </p>
                </div>
                {/* <div className="flex justify-between mb-1">
              <p className="text-sm">SGST</p>
              <p className="text-sm font-medium">₹ 15,543.00</p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm">CGST</p>
              <p className="text-sm font-medium">₹ 15,543.00</p>
            </div> */}
                <hr className="my-2" />{" "}
                <div className="flex items-start justify-between">
                  <p className="text-sm">Coupon</p>
                  {order?.coupon ? (
                    <div className="flex flex-col items-end justify-end gap-1 ">
                      <div className="flex items-center gap-1 px-2 py-1 text-xs bg-blue-200 text-blue-600 font-semibold rounded-md ">
                        {order?.coupon?.name}{" "}
                        <XMarkIcon
                          className="text-red-500 size-5 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              setCheckoutOrder({ ...order, coupon: undefined })
                            );
                          }}
                        />
                      </div>
                      <p className="text-gray-500 text-[10px] italic">
                        {order?.coupon?.discount_percentage ?? 0}% Discount
                        Applied
                      </p>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <div className="flex items-start justify-end gap-2 ">
                        <input
                          className="border-2 border-gray-200 rounded-md text-xs p-1 w-1/2"
                          onChange={(e) => {
                            setCouponCode({ code: e.target.value });
                          }}
                        />
                        <PrimaryButton
                          disabled={(couponCode?.code?.length ?? 0) <= 0}
                          loading={btnLoading}
                          className="py-1.5 px-2 text-xs text-white bg-geovetraGreen-500 rounded-lg hover:bg-geovetraGreen-600 inline-flex items-center"
                          onClick={handleVerifyCouponCode}
                        >
                          Apply
                        </PrimaryButton>
                      </div>
                      {couponCode?.error && (
                        <p className="text-[10px] text-center text-red-500">
                          {couponCode?.error}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <hr className="my-2" />{" "}
                <div className="flex justify-between text-base font-semibold mb-6">
                  <p className="">Total</p>
                  <p className=" font-medium">
                    ₹{" "}
                    {getFinalTotal().toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </>
            )}
            <PrimaryButton
              loading={checkOutBtnLoading}
              disabled={!order?.shipping_address_details}
              btnClassName={`flex items-center justify-center gap-1 w-full ${
                !order?.shipping_address_details &&
                " opacity-75 cursor-not-allowed"
              }`}
              onClick={() => {
                handleCheckoutOrder();
              }}
            >
              <ShoppingCartIcon className="size-5" /> Proceed to Pay
            </PrimaryButton>
            {/* Instruction */}
            <div className="mt-6 mx-2">
              {instruction.map((item, index) => {
                return (
                  <li key={index} className="text-red-600 text-[10px]">
                    {item}
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
