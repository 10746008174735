import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ReactNode, useEffect, useState } from "react";
import { IResponse, useAuth } from "../../providers/AuthContext";
import {
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  GlobeAltIcon,
  PlusCircleIcon,
  ShoppingCartIcon,
  TicketIcon,
  TruckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import PrimaryButton from "../atoms/PrimaryButton";
import {
  ICoupon,
  IOrder,
  ISubscription,
  IUserAddress,
} from "../../types/CommonType";
import api from "../../helpers/api";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import SelectInputField from "../atoms/SelectInputField";
import { DurationOfRecharges } from "../../constants/ConstantData";
import { openModal } from "../../redux/reducers/uiReducer";

interface RenewSubscriptionModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  subscriptions?: ISubscription[];
}

interface IRenewSubscription {
  subscription_ids?: string[];
  number_of_days: number;
  coupon?: ICoupon;
  shipping_address_details?: IUserAddress;
  billing_address_details?: IUserAddress;
}

const RenewSubscriptionModal: React.FC<RenewSubscriptionModalProps> = ({
  visible,
  setVisible,
  subscriptions,
}) => {
  const { selectedCompany, auth } = useAuth();
  const [addresses, setAddresses] = useState<IUserAddress[]>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [checkOutBtnLoading, setCheckOutBtnLoading] = useState(false);
  const [checkoutRenewOrder, setCheckoutRenewOrder] = useState();
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState<{
    code: string;
    error?: string;
  }>();

  const [payload, setPayload] = useState<IRenewSubscription>({
    number_of_days: 91,
  });

  const licenseCost: number = Number(
    process.env.REACT_APP_LICENSE_COST ?? "800"
  );
  const gstRate: number = Number(process.env.REACT_APP_GST_RATE ?? "18");

  const renewNumberOfLicences = subscriptions?.length ?? 0;

  const instruction = [
    "Orders above ₹10 lakhs are not allowed, Order in segments if it exceeds over it",
    "If Order is not fullfilled during checkout, Please check in Orders page",
  ];

  useEffect(() => {
    api
      .GET<IResponse<IUserAddress[]>>(`/user-addresses`, {
        populate: JSON.stringify([
          "pincode",
          "user",
          "city",
          "state",
          "country",
        ]),
      })
      .then((response) => {
        const adsData = response?.data?.data;
        if (adsData) {
          setAddresses(adsData);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  const RenderSection = ({ children }: { children: ReactNode }) => {
    return (
      <ul className="mb-10 ">
        <div className="w-full py-4 pl-0 ">{children}</div>
      </ul>
    );
  };

  const getNetTotalAmount = () => {
    return (
      (selectedCompany?.plan_details?.cost_per_day ?? 0) *
      (payload?.number_of_days ?? 0) *
      (renewNumberOfLicences ?? 0)
    );
  };

  const getDiscountedAmount = () => {
    return (
      getNetTotalAmount() *
      (payload?.coupon?.discount_percentage
        ? payload?.coupon?.discount_percentage / 100
        : 0)
    );
  };

  const getFinalTotal = () => {
    const discountAmount = getNetTotalAmount() - getDiscountedAmount();
    const taxAmount = discountAmount * (gstRate / 100);
    return taxAmount + discountAmount;
  };

  const handleVerifyCouponCode = () => {
    setBtnLoading(true);
    api
      .GET<IResponse<ICoupon>>(
        `${selectedCompany?.domain}/orders/apply-coupon/${couponCode?.code}`
      )
      .then((response) => {
        if (response?.data?.data) {
          setPayload({ ...payload, coupon: response?.data?.data });
        } else {
          setCouponCode({
            ...couponCode,
            error: response?.data?.message,
          } as any);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const handleCheckoutOrder = () => {
    setCheckOutBtnLoading(true);
    api
      .POST<IResponse<IOrder>>(
        `${selectedCompany?.domain}/orders/renewRecharges`,
        {
          ...payload,
          subscription_ids: subscriptions?.map((v) => v?.id) as any,
        }
      )
      .then((response) => {
        if (response?.data?.data) {
          api
            .POST(
              `/${selectedCompany?.domain}/orders/${response?.data?.data?.id}/pay`
            )
            .then((res: any) => {
              if (res?.data?.redirect) window.location.href = res.data.redirect;
            });
        } else {
          toast.error(response?.data?.message ?? "");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setCheckOutBtnLoading(false);
      });
  };

  return (
    <Transition show={visible}>
      <Dialog className="relative z-20" onClose={setVisible}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 md:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="rounded-2xl bg-[#F9FAFB] text-left shadow-xl transition-all md:my-8 w-[90%] md:w-[800px] justify-center items-center flex flex-col py-4 md:px-4 px-2 md:p-8">
                <div className="text-start md:mt-0 md:text-left w-full">
                  <DialogTitle
                    as="h3"
                    className="flex flex-1 text-base md:text-xl text-center items-center justify-between w-full font-semibold md:font-semibold leading-6 text-gray-900 mb-4 md:mb-4"
                  >
                    Summary
                  </DialogTitle>
                </div>
                <div className="bg-[#F9FAFB] p-4 rounded-xl w-full">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-1">
                      <p className="flex items-center gap-1 text-base md:text-lg font-semibold line-clamp-1">
                        <BuildingOffice2Icon className="size-5 text-black" />
                        {selectedCompany?.name}
                      </p>
                      <div className="flex text-xs justify-between items-baseline">
                        <a
                          href={`https://${selectedCompany?.domain}.geovetra.com`}
                          target="_blank"
                          className="flex items-center gap-1 underline text-start text-blue-500 font-semibold hover:text-blue-700"
                        >
                          <GlobeAltIcon className="size-5" />{" "}
                          {selectedCompany?.domain}
                        </a>
                      </div>
                    </div>
                    <div className="flex flex-col items-end text-end gap-2">
                      <p className="bg-red-100 p-1 px-2 rounded-xl w-fit text-[10px] text-red-500 font-medium line-clamp-1">
                        {selectedCompany?.gstin}
                      </p>
                      <p className="text-xs">
                        {selectedCompany?.type_of_company}
                      </p>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <div className="flex items-center justify-between">
                    <p className="flex items-center gap-1 text-base md:text-lg font-semibold line-clamp-1">
                      <ShoppingCartIcon className="size-5 text-black" />
                      Plan:
                    </p>
                    <p className="flex items-center gap-1 text-base md:text-lg font-semibold line-clamp-1">
                      <p className="bg-green-100 p-1 px-2 rounded-xl w-fit text-xs text-green-500 capitalize ">
                        {selectedCompany?.plan_details?.title}
                      </p>
                    </p>
                  </div>
                  <hr className="my-4" />{" "}
                  <div className="md:mb-4">
                    <RenderSection>
                      <div className="flex gap-4 items-center overflow-x-auto bg-[#F9FAFB] shadow-md rounded-xl p-4">
                        <div className="w-full">
                          <p className="text-xs md:text-sm font-medium mb-1">
                            Number of Licenses
                          </p>
                          <p className="text-black p-2 bg-white border-gray-200  rounded-md text-xs md:text-sm font-normal border">
                            {subscriptions?.length}
                          </p>
                        </div>
                        <SelectInputField
                          data={DurationOfRecharges}
                          label="Recharge Duration in Days"
                          defaultValue={payload?.number_of_days}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              number_of_days: Number(e?.target?.value) ?? 0,
                            });
                          }}
                          placeholder="Select recharge duration in days"
                          SelectInputClassname="w-full"
                        />
                      </div>
                    </RenderSection>
                  </div>
                  <hr className="my-4" />{" "}
                  <div className="mb-4 md:max-w-2xl">
                    <p className="flex items-center gap-1 text-sm md:text-base font-semibold line-clamp-1">
                      <TicketIcon className="size-5 text-black" />
                      Billing Address
                    </p>
                    <RenderSection>
                      <div className="flex gap-4 items-center overflow-x-auto bg-[#F9FAFB] shadow-md rounded-xl p-4">
                        {addresses &&
                          addresses?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`flex bg-white flex-col items-start border min-w-screen gap-4 rounded-xl p-6 cursor-default min-w-60 ${
                                  item?.id ===
                                  payload?.billing_address_details?.id
                                    ? "outline outline-geovetraGreen-500 outline-2 outline-offset-2"
                                    : ""
                                }`}
                                onClick={() => {
                                  setPayload({
                                    ...payload,
                                    billing_address_details: item,
                                  });
                                }}
                              >
                                <div className="flex justify-between items-center gap-1 w-full">
                                  <div className="p-2 rounded-xl bg-red-100">
                                    <BuildingStorefrontIcon className="size-6 text-red-600" />
                                  </div>
                                  <div className="flex items-center justify-center gap-3">
                                    <div className="flex flex-col justify-center items-start">
                                      <p className="text-sm mb-1 font-semibold line-clamp-1">
                                        {auth?.user?.name}
                                      </p>
                                      <div className="bg-blue-100 p-1 px-2 rounded-xl w-fit">
                                        <p className="text-xs text-blue-600 font-medium">
                                          {auth?.user?.phone_number}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        defaultChecked={
                                          item?.id ===
                                          payload?.billing_address_details?.id
                                        }
                                        type="radio"
                                        className="w-4 h-4 text-blue-600"
                                        onClick={() => {}}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex items-center w-full justify-between">
                                  <p
                                    className={`text-xs font-semibold text-gray-500 line-clamp-2 flex-1`}
                                  >
                                    {item.address_line_1}{" "}
                                    {item.address_line_2
                                      ? `, ${item.address_line_2} `
                                      : ""}
                                    {item.city?.name
                                      ? `, ${item.city?.name}`
                                      : ""}
                                    {item.state?.name
                                      ? `, ${item.state?.name}`
                                      : ""}
                                    {item.country?.name
                                      ? `, ${item.country?.name}`
                                      : ""}
                                    {item.pincode?.name
                                      ? `, ${item.pincode?.name}`
                                      : ""}
                                  </p>
                                  <div className="self-end"></div>
                                </div>
                              </div>
                            );
                          })}
                        <div
                          className="flex bg-white flex-col items-center border-dashed border-2 min-w-screen gap-4 rounded-xl p-8 cursor-pointer hover:bg-gray-100 min-w-60"
                          onClick={() => {
                            dispatch(openModal("AddressModal"));
                          }}
                        >
                          <PlusCircleIcon className="size-8 text-gray-400" />
                          <p className="text-base text-gray-400">
                            Create New Address
                          </p>
                        </div>
                      </div>
                    </RenderSection>
                  </div>
                  <div className="md:max-w-2xl">
                    <p className="flex items-center gap-1 text-sm md:text-base font-semibold line-clamp-1">
                      <TruckIcon className="size-5 text-black" />
                      Shipping Address
                    </p>
                    <RenderSection>
                      <div className="flex gap-4 items-center overflow-x-auto bg-[#F9FAFB] shadow-md rounded-xl p-4">
                        {addresses &&
                          addresses?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`flex bg-white flex-col items-start border min-w-screen gap-4 rounded-xl p-6 cursor-default min-w-60 ${
                                  item?.id ===
                                  payload?.shipping_address_details?.id
                                    ? "outline outline-geovetraGreen-500 outline-2 outline-offset-2"
                                    : ""
                                }`}
                                onClick={() => {
                                  setPayload({
                                    ...payload,
                                    shipping_address_details: item,
                                  });
                                }}
                              >
                                <div className="flex justify-between items-center w-full gap-1">
                                  <div className="p-2 rounded-xl bg-red-100">
                                    <BuildingStorefrontIcon className="size-6 text-red-600" />
                                  </div>
                                  <div className="flex items-center justify-center gap-3">
                                    <div className="flex flex-col justify-center items-start">
                                      <p className="text-sm mb-1 font-semibold line-clamp-1">
                                        {auth?.user?.name}
                                      </p>
                                      <div className="bg-blue-100 p-1 px-2 rounded-xl w-fit">
                                        <p className="text-xs text-end text-blue-600 font-medium">
                                          {auth?.user?.phone_number}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        defaultChecked={
                                          item?.id ===
                                          payload?.shipping_address_details?.id
                                        }
                                        type="radio"
                                        className="w-4 h-4 text-blue-600"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex items-center w-full justify-between">
                                  <p
                                    className={`text-xs font-semibold text-gray-500 line-clamp-2 flex-1`}
                                  >
                                    {item.address_line_1}{" "}
                                    {item.address_line_2
                                      ? `, ${item.address_line_2} `
                                      : ""}
                                    {item.city?.name
                                      ? `, ${item.city?.name}`
                                      : ""}
                                    {item.state?.name
                                      ? `, ${item.state?.name}`
                                      : ""}
                                    {item.country?.name
                                      ? `, ${item.country?.name}`
                                      : ""}
                                    {item.pincode?.name
                                      ? `, ${item.pincode?.name}`
                                      : ""}
                                  </p>
                                  <div className="self-end"></div>
                                </div>
                              </div>
                            );
                          })}
                        <div
                          className="flex bg-white flex-col items-center border-dashed border-2 min-w-screen gap-4 rounded-xl p-8 cursor-pointer hover:bg-gray-100 min-w-60"
                          onClick={() => {
                            dispatch(openModal("AddressModal"));
                          }}
                        >
                          <PlusCircleIcon className="size-8 text-gray-400" />
                          <p className="text-base text-gray-400">
                            Create New Address
                          </p>
                        </div>
                      </div>
                    </RenderSection>
                  </div>
                  <hr className="my-4" />{" "}
                  {/* <div className="flex items-center justify-between mb-1">
                    <p className="md:text-sm text-xs">{`License Cost: ₹ ${licenseCost} x ${renewNumberOfLicences} Unit`}</p>
                    <p className="text-sm font-medium">
                      ₹{" "}
                      {(licenseCost * renewNumberOfLicences).toLocaleString(
                        "en-IN",
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </p>
                  </div> */}
                  <div className="flex items-center justify-between mb-1">
                    <p className="md:text-sm text-xs">Recharge Cost:</p>
                    <p className="text-sm font-medium">
                      ₹{" "}
                      {getNetTotalAmount().toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mb-1">
                    <p className="md:text-sm text-xs">
                      Coupon Discount (
                      {payload?.coupon?.discount_percentage ?? 0}
                      %):
                    </p>
                    <p className="text-sm font-medium text-red-500">
                      - ₹{" "}
                      {getDiscountedAmount().toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mb-1">
                    <p className="md:text-sm text-xs">GST ({gstRate}%):</p>
                    <p className="text-sm font-medium">
                      ₹{" "}
                      {(
                        (getNetTotalAmount() - getDiscountedAmount()) *
                        (gstRate / 100)
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <hr className="my-2" />{" "}
                  <div className="flex items-start justify-between">
                    <p className="text-sm">Coupon</p>
                    {payload?.coupon ? (
                      <div className="flex flex-col items-end justify-end gap-1 ">
                        <div className="flex items-center gap-1 px-2 py-1 text-xs bg-blue-200 text-blue-600 font-semibold rounded-md ">
                          {payload?.coupon?.name}{" "}
                          <XMarkIcon
                            className="text-red-500 size-5 cursor-pointer"
                            onClick={() => {
                              setPayload({
                                ...payload,
                                coupon: undefined,
                              });
                            }}
                          />
                        </div>
                        <p className="text-gray-500 text-[10px] italic">
                          {payload?.coupon?.discount_percentage ?? 0}% Discount
                          Applied
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        <div className="flex items-start justify-end gap-2 ">
                          <input
                            className="border-2 border-gray-200 rounded-md text-xs p-1 w-1/2"
                            onChange={(e) => {
                              setCouponCode({ code: e.target.value });
                            }}
                          />
                          <PrimaryButton
                            loading={btnLoading}
                            className="py-1.5 px-2 text-xs text-white bg-geovetraGreen-500 rounded-lg hover:bg-geovetraGreen-600 inline-flex items-center"
                            onClick={handleVerifyCouponCode}
                          >
                            Apply
                          </PrimaryButton>
                        </div>
                        {couponCode?.error && (
                          <p className="text-[10px] text-center text-red-500">
                            {couponCode?.error}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <hr className="my-2" />{" "}
                  <div className="flex justify-between text-base font-semibold mb-6">
                    <p className="">Total</p>
                    <p className=" font-medium">
                      ₹{" "}
                      {getFinalTotal().toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <PrimaryButton
                    loading={checkOutBtnLoading}
                    disabled={!payload?.shipping_address_details}
                    btnClassName={`flex items-center justify-center gap-1 w-full ${
                      !payload?.shipping_address_details &&
                      " opacity-75 cursor-not-allowed"
                    }`}
                    onClick={() => {
                      handleCheckoutOrder();
                    }}
                  >
                    <ShoppingCartIcon className="size-5" /> Proceed to Pay
                  </PrimaryButton>
                  {/* Instruction */}
                  <div className="mt-6 mx-2">
                    {instruction.map((item, index) => {
                      return (
                        <li key={index} className="text-red-600 text-[10px]">
                          {item}
                        </li>
                      );
                    })}
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RenewSubscriptionModal;
