import {
  BuildingOffice2Icon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { openModal } from "../../redux/reducers/uiReducer";
import { useDispatch } from "react-redux";
import { useAuth } from "../../providers/AuthContext";
import { setOrganization } from "../../redux/reducers/organizationReducer";
import { setFormMode } from "../../redux/reducers/formModeReducer";

interface OrganizationProps {}

const Organization: React.FC<OrganizationProps> = () => {
  const dispatch = useDispatch();
  const { auth, selectedCompany, setNewCompany } = useAuth();

  return (
    <div className="bg-[#F9FAFB] p-2 rounded-xl w-full">
      <p className="text-gray-600 font-semibold text-base mb-4 pt-4 px-4">
        Your Organizations
      </p>
      <div className="flex flex-col sm:grid sm:grid-cols-2 md:grid md:grid-cols-3 w-full items-center justify-center self-center gap-4 pb-4 md:px-4">
        {auth?.user_companies?.map((item, index) => {
          return (
            <div
              key={index}
              className={`p-4 w-full bg-white rounded-xl cursor-pointer shadow hover:bg-gray-200 ${
                item?.company?.domain === selectedCompany?.domain
                  ? "outline outline-geovetraGreen-500 outline-2 outline-offset-2"
                  : ""
              }`}
              onClick={() => {
                dispatch(setFormMode("read"));
                dispatch(setOrganization(item));
                dispatch(openModal("OrganizationModal"));
              }}
            >
              <div className="flex items-center justify-between gap-2 mb-2">
                {item?.company?.logo ? (
                  <img
                    src={item?.company?.logo}
                    className="size-8 md:size-12 rounded-2xl border border-gray-400"
                  />
                ) : (
                  <div className="size-8 md:size-12 rounded-2xl bg-gray-200 flex items-center justify-center">
                    <BuildingOffice2Icon className="size-5 md:size-6 text-black" />
                  </div>
                )}

                <div className="flex flex-col justify-between items-start flex-1">
                  <p className="text-base md:text-lg font-semibold line-clamp-1">
                    {item?.company?.name}
                  </p>
                  <p className="bg-red-100 p-1 px-2 rounded-xl w-fit text-[10px] text-red-500 font-medium line-clamp-1">
                    {item?.company?.gstin}
                  </p>
                </div>
                <div className="flex items-center">
                  <input
                    checked={item?.company?.domain === selectedCompany?.domain}
                    type="radio"
                    className="w-4 h-4 text-blue-600"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setNewCompany(item?.company);
                    }}
                  />
                </div>
              </div>
              <div className="flex text-xs justify-between items-baseline my-2 mt-4">
                <a
                  href={`https://${item?.company?.domain}.geovetra.com`}
                  target="_blank"
                  className="underline text-start text-blue-500 font-semibold hover:text-blue-700"
                >
                  {item?.company?.domain}
                </a>
                {item?.role && (
                  <p className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-2xl border border-green-700">
                    {item.role as any}
                  </p>
                )}
              </div>
              <div className="flex text-xs justify-between items-center">
                <p>{item?.company?.type_of_company}</p>
                <p className="text-end">{item?.company?.created_at?.date}</p>
              </div>
            </div>
          );
        })}
        {auth?.user_companies && auth?.user_companies?.length < 5 && (
          <div
            className={`flex flex-col items-center justify-center text-center gap-4 p-6 w-full bg-[#F9FAFB] rounded-xl cursor-pointer border-2 border-dashed hover:bg-gray-200`}
            onClick={() => {
              dispatch(setFormMode("create"));
              dispatch(openModal("OrganizationModal"));
            }}
          >
            <PlusCircleIcon className="size-10 text-gray-400" />
            <p className="text-base font-semibold text-gray-400">
              Create Organization
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Organization;
