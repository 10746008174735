import { DialogPanel, DialogTitle, TransitionChild } from "@headlessui/react";
import CommonModal from "./CommonModal";
import { SubmitHandler, useForm } from "react-hook-form";
import { REGEX } from "../../constants/RegexConstants";
import InputField from "../atoms/InputField";
import SelectInputField from "../atoms/SelectInputField";
import PrimaryButton from "../atoms/PrimaryButton";
import { useDispatch } from "react-redux";
import { closeModal, setLoading } from "../../redux/reducers/uiReducer";
import { FormMode, ICompany } from "../../types/CommonType";
import api from "../../helpers/api";
import PincodeSearchableInput from "../atoms/searchableInputs/PincodeSearchableInput";
import setFormErrors from "../../helpers/setFormErrors";
import { useAuth } from "../../providers/AuthContext";
import toast from "react-hot-toast";
import useOrganization from "../../redux/hooks/useOrganization";
import { useEffect, useState } from "react";
import useFormMode from "../../redux/hooks/useFormMode";
import { setFormMode } from "../../redux/reducers/formModeReducer";
import { omit } from "lodash";
import ProfileImageUpload from "../atoms/ProfileImageUpload";
const lodash = require("lodash");

interface OrganizationModalProps {}

const OrganizationModal: React.FC<OrganizationModalProps> = () => {
  const { refreshUser } = useAuth();
  const { organization } = useOrganization();
  const dispatch = useDispatch();
  const { formMode } = useFormMode();

  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ICompany>();

  const onSubmit: SubmitHandler<ICompany> = (data) => {
    if (formMode === "read") {
      return;
    }
    if (formMode === "update") {
      const removeUpdateFields = omit(data, ["domain"]);
      dispatch(
        setLoading({ label: "Updating Organization...", isLoading: true })
      );
      api
        .PUT(`companies/${data?.id}`, removeUpdateFields)
        .then((res: any) => {
          if (res?.data?.data) {
            reset();
            refreshUser();
            handleClose();
            // dispatch(closeModal("OrganizationModal"));
            toast.success("Organization updated successfully");
          } else {
            setFormErrors(res?.data?.errors, setError);
          }
        })
        .catch(() => {
          toast.error("Unable to update address");
        })
        .finally(() => {
          dispatch(setLoading({ isLoading: false }));
        });
    } else {
      dispatch(
        setLoading({ label: "Creating Organization...", isLoading: true })
      );
      api
        .POST(`companies`, data)
        .then((res: any) => {
          if (res?.data?.data) {
            reset();
            refreshUser();
            handleClose();
            // dispatch(closeModal("OrganizationModal"));
          } else {
            toast.error(res?.data.message);
            setFormErrors(res?.data?.errors, setError);
          }
        })
        .catch((error) => {
          toast.error("Unable to create address");
        })
        .finally(() => {
          dispatch(setLoading({ isLoading: false }));
        });
    }
  };

  useEffect(() => {
    if (organization?.id) {
      reset(organization?.company);
    }
  }, [organization, formMode]);

  const renderSubmitBtnText = (fM: FormMode) => {
    switch (fM) {
      case "create":
        return "Create";
      case "read":
        return "Edit";
      case "update":
        return "Update";
    }
  };

  const handleClose = () => {
    reset();
    // reset({
    //   name: "",
    //   gstin: "",
    //   phone_number: "",
    //   domain: "",
    //   address_line_1: "",
    //   address_line_2: "",
    // });
    dispatch(closeModal("OrganizationModal"));
  };

  return (
    <CommonModal name="OrganizationModal" onClose={handleClose}>
      <div className="fixed inset-0 w-screen overflow-auto z-40">
        <div className="flex min-h-full items-center justify-center p-4 md:p-0">
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel className="rounded-2xl bg-white text-left shadow-xl transition-all md:my-8 md:w-fit justify-center items-center flex flex-col p-6 px-4">
              <div className="flex flex-col items-center justify-center w-full">
                <DialogTitle
                  as="h3"
                  className="text-xl text-center font-semibold leading-6 text-gray-900"
                >
                  Organization
                </DialogTitle>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col md:justify-center items-start md:px-4"
                >
                  <DialogTitle
                    as="h3"
                    className="text-sm md:text-lg text-start font-medium leading-6 text-gray-900 my-4"
                  >
                    Organization Details
                  </DialogTitle>
                  <div className="flex flex-col p-6 gap-2 shadow bg-[#F9FAFB] rounded-2xl">
                    <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-8 w-full my-4">
                      <div className="flex justify-center items-center">
                        {/* <UploadLogo /> */}
                        {watch("logo") === null && formMode === "read" ? (
                          <div className="flex justify-center items-center h-28 w-28 relative bg-gray-200 rounded-full border border-gray-400 capitalize text-4xl text-gray-800">
                            {watch("name").slice(0, 1)}
                          </div>
                        ) : (
                          <ProfileImageUpload
                            disabled={formMode === "read"}
                            image={watch("logo")}
                            onImageChange={(img) => {
                              setValue("logo", img);
                            }}
                          />
                        )}
                      </div>
                      <div className="flex flex-col gap-2 flex-1">
                        <InputField
                          inputClassName="md:w-full"
                          required
                          label="Organization Name"
                          placeholder="Enter your organization name"
                          type="text"
                          error={errors?.name?.message}
                          register={register("name", {
                            required: "This Field is Required",
                            pattern: {
                              value: REGEX.onlyAlphabets,
                              message: "only alphabets allow",
                            },
                          })}
                        />
                        <InputField
                          label="GSTIN"
                          inputClassName="md:w-full"
                          disabled={formMode !== "create"}
                          required
                          placeholder="GST Number"
                          type="text"
                          error={errors?.gstin?.message}
                          register={register("gstin", {
                            required: "This Field is Required",
                            pattern: {
                              value: REGEX.gstin,
                              message: "Invalid GST Number",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center justify-center gap-2 w-full">
                      <InputField
                        label="Company Contact Number"
                        required
                        placeholder="Company Contact number"
                        type="text"
                        error={errors?.phone_number?.message}
                        register={register("phone_number", {
                          required: "This Field is Required",
                          pattern: {
                            value: REGEX.phoneNumber,
                            message: "Invalid phone number",
                          },
                        })}
                      />
                      <SelectInputField
                        label="Company Type"
                        required
                        data={[
                          "Private Limited Company",
                          "Public Limited Company",
                          "Limited Liability partnership",
                          "One Person Company",
                          "Solo Propritorship",
                          "Section 8 Company",
                        ]}
                        register={{
                          ...register("type_of_company", {
                            required: "This Field is Required",
                          }),
                        }}
                        error={errors?.type_of_company?.message}
                      />
                    </div>
                    <InputField
                      label="Sub-Domain"
                      required
                      disabled={formMode !== "create"}
                      placeholder="Sub-Domain"
                      type="text"
                      className="text-black border p-2 rounded-md text-xs md:text-sm font-normal w-full"
                      error={errors?.domain?.message}
                      register={register("domain", {
                        required: "This Field is Required",
                        pattern: {
                          value: REGEX.domain,
                          message: "Invalid Domain",
                        },
                      })}
                    />
                    <p className="text-xs text-gray-400 italic ml-2">
                      {watch("domain")}.geovetra.com
                    </p>
                  </div>
                  <DialogTitle
                    as="h3"
                    className="text-sm md:text-lg text-start font-medium leading-6 text-gray-900 my-4"
                  >
                    Address Details
                  </DialogTitle>
                  <div className="flex flex-col p-6 gap-2 shadow bg-[#F9FAFB] rounded-2xl w-full">
                    <div className="flex flex-col w-full items-center justify-center gap-2">
                      <InputField
                        label="Address line 1"
                        required
                        placeholder="Enter your address"
                        type="text"
                        className="text-black border p-2 rounded-md text-xs md:text-sm font-normal w-full"
                        error={errors?.address_line_1?.message}
                        register={register("address_line_1", {
                          required: "This Field is Required",
                        })}
                      />
                      <InputField
                        label="Address line 2"
                        placeholder="Enter your address"
                        type="text"
                        className="text-black border p-2 rounded-md text-xs md:text-sm font-normal w-full"
                        error={errors?.address_line_2?.message}
                        register={register("address_line_2", {})}
                      />
                    </div>
                    <div className="flex flex-col md:flex-row items-center justify-center gap-2">
                      {/* pincode and state */}
                      <PincodeSearchableInput
                        watch={watch}
                        formKey={"pincode"}
                        setValue={setValue}
                        error={errors?.pincode?.message}
                        register={register("pincode", {
                          required: "This Field is Required",
                        })}
                      />
                      <InputField
                        label="State"
                        placeholder="Enter your state"
                        type="text"
                        error={errors?.state?.message}
                        register={register("state.name")}
                        disabled={true}
                      />
                    </div>
                    <div className="flex flex-col md:flex-row items-center justify-center gap-2">
                      {/* city and country */}
                      <InputField
                        label="City"
                        placeholder="Enter your city"
                        type="text"
                        error={errors?.city?.message}
                        register={register("city.name")}
                        disabled={true}
                      />
                      <InputField
                        label="Country"
                        placeholder="Enter your country"
                        type="text"
                        error={errors?.country?.message}
                        register={register("country.name")}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="md:self-end w-full md:w-full mt-6 flex justify-between items-center">
                    <button
                      type="button"
                      className="py-2 px-5 text-sm font-medium text-black bg-gray-50 rounded-xl hover:bg-gray-200 inline-flex items-center border border-gray-300"
                      onClick={() => {
                        reset({});
                        dispatch(closeModal("OrganizationModal"));
                      }}
                    >
                      Cancel
                    </button>

                    {formMode === "read" ? (
                      <PrimaryButton
                        type="button"
                        btnClassName="w-fit text-center flex items-center justify-center rounded-[6px] h-[38px]"
                        onClick={(e: any) => {
                          e.preventDefault();
                          dispatch(setFormMode("update"));
                        }}
                      >
                        {renderSubmitBtnText(formMode)}
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        type="submit"
                        btnClassName="w-fit text-center flex items-center justify-center rounded-[6px] h-[38px]"
                      >
                        {renderSubmitBtnText(formMode)}
                      </PrimaryButton>
                    )}
                  </div>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </CommonModal>
  );
};

export default OrganizationModal;
