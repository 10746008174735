import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import api from "../helpers/api";
import { ICompany, ICompanyUser, IUser } from "../types/CommonType";
import { IRegisterFormInput } from "../components/modals/RegisterModal";
import { ILoginFormInput } from "../components/modals/LoginModal";
import ApiRoutes from "../constants/ApiRoutes";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { closeModal, setLoading } from "../redux/reducers/uiReducer";

export interface IAuth {
  user: IUser;
  user_company?: ICompanyUser;
  user_companies?: ICompanyUser[];
}

export type IErrors<T> = {
  [Key in keyof T]: string[];
};

export interface IResponse<T> {
  data: T;
  message?: string;
  errors?: IErrors<T>;
  success?: boolean;
}

export interface ISession {
  token?: string;
  message?: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | null | undefined;
  login: (
    values: ILoginFormInput,
    onError?: (message: string | IErrors<ILoginFormInput>) => void,
    onSuccess?: (response?: ISession) => void
  ) => Promise<void>;
  register: (
    values: IRegisterFormInput,
    onError?: (errors: string | IErrors<IRegisterFormInput>) => void,
    onSuccess?: (response?: ISession) => void
  ) => Promise<void>;
  logout: () => void;
  auth: IAuth | null | undefined;
  selectedCompany: ICompany | null | undefined;
  setNewCompany: (company?: ICompany | null) => void;
  refreshUser: () => void;
  handleLoginRegisterResponse: (response: ISession) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    localStorage.getItem("token") ? true : false
  );
  const [auth, setAuth] = useState<IAuth | null>();
  const [tokenState, setTokenState] = useState<string | null>();
  const [selectedCompany, setSelectedCompany] = useState<
    ICompany | null | undefined
  >(
    localStorage.getItem("selectedCompany") &&
      // @ts-ignore
      JSON.parse(localStorage.getItem("selectedCompany"))
  );
  const dispatch = useDispatch();

  const setAuthData = (authData: IAuth) => {
    setAuth(authData);
    setIsAuthenticated(true);
  };
  useEffect(() => {
    refreshUser();
  }, [tokenState]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const company =
      selectedCompany ??
      JSON.parse(localStorage.getItem("selectedCompany") ?? "{}");
    if (token && company?.domain) {
      api
        .GET<IAuth>(`/me?domain=${company?.domain}`, {
          token: token,
        })
        .then((response) => {
          // @ts-ignore
          if (response?.data?.message === "Unauthenticated.") {
            logout();
          } else {
            setAuthData(response?.data);
          }
        });
    }
  }, [selectedCompany]);

  const refreshUser = () => {
    dispatch(setLoading({ isLoading: true, label: "Fetching Data..." }));
    const token = localStorage.getItem("token");
    if (token) {
      api
        .GET<IAuth>(`/me`, {
          token: token,
        })
        .then((response) => {
          const data = response?.data;
          if (data) {
            setAuthData(data);
            if (
              selectedCompany &&
              data?.user_companies?.find(
                (e) => e?.company?.domain === selectedCompany?.domain
              )
            ) {
            } else {
              if (
                data?.user_companies &&
                data?.user_companies?.[0]?.company?.domain
              ) {
                setNewCompany(data?.user_companies?.[0]?.company);
              }
            }
          } else {
            logout();
          }
        })
        .catch((error) => {
          toast.error(error?.message);
        })
        .finally(() => {
          dispatch(setLoading({ isLoading: false }));
        });
    } else {
      logout();
    }
  };

  const handleLoginRegisterResponse = (response: ISession) => {
    if (response?.token) {
      localStorage.setItem("token", response?.token);
      setTokenState(response?.token);
      dispatch(closeModal("LoginModal"));
      dispatch(closeModal("RegisterModal"));
    }
    if (response?.message) {
      toast.success(response?.message ?? "", { duration: 2000 });
    }
  };

  const login = async (
    { email, password }: ILoginFormInput,
    onError?: (errors: string | IErrors<ILoginFormInput>) => void,
    onSuccess?: (response?: ISession) => void
  ) => {
    try {
      const response = await api.POST<ISession>(ApiRoutes.login, {
        email,
        password,
      });
      // toast.error(response.statusText);
      if (response?.status == 200) {
        handleLoginRegisterResponse(response.data);
        onSuccess && onSuccess(response.data);
      } else {
        onError &&
          onError(
            response?.status == 422
              ? //@ts-ignore
                response?.data.errors
              : //@ts-ignore
                response?.data?.message ?? ""
          );
      }
    } catch (error) {
      // console.error("Login error", error);
      // throw error;
    }
  };

  const register = async (
    {
      email,
      password,
      password_confirmation,
      name,
      phone_number,
      phone_number_country_code,
    }: IRegisterFormInput,
    onError?: (errors: string | IErrors<IRegisterFormInput>) => void,
    onSuccess?: (response?: ISession) => void
  ) => {
    try {
      const response = await api.POST<ISession>(ApiRoutes.register, {
        email,
        password,
        password_confirmation,
        name,
        phone_number,
        phone_number_country_code,
      });
      if (response?.status == 200) {
        handleLoginRegisterResponse(response.data);
        onSuccess && onSuccess(response.data);
      } else {
        //@ts-ignore
        // toast.error(response?.data?.message, { position: "top-right" });
        onError &&
          onError(
            response?.status == 422
              ? //@ts-ignore
                response?.data.errors
              : //@ts-ignore
                response?.data?.message ?? ""
          );
      }
    } catch (error) {
      // console.error("Registration error", error);
      // throw error;
    }
  };

  const logout = () => {
    dispatch(setLoading({ isLoading: true, label: "Logging Out..." }));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedCompany");
    setIsAuthenticated(false);
    setAuth(null);
    setNewCompany(null);
    dispatch(setLoading({ isLoading: false }));
  };

  const setNewCompany = (company: ICompany | null | undefined) => {
    if (company) {
      localStorage.setItem("selectedCompany", JSON.stringify(company));
    } else {
      localStorage.removeItem("selectedCompany");
    }
    setSelectedCompany(company);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        token: tokenState,
        login,
        register,
        logout,
        auth,
        selectedCompany,
        setNewCompany,
        refreshUser,
        handleLoginRegisterResponse,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
