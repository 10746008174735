import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";
import toast from "react-hot-toast";

// Create an instance of Axios
const axiosInstance: AxiosInstance = axios.create({
  // baseURL: "https://apicc.geovetra.com/api/", // Replace with your API's base URL
  // baseURL: "http://api.geovetra-dev.localhost/api/", // Replace with your API's base URL
  baseURL: process.env.REACT_APP_BASE_URL ?? "http://192.168.1.190:8000/api/", // Replace with your API's base URL
  timeout: 50000, // Set a timeout for requests (optional)
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => status < 500,
});

// Request interceptor to add headers or other configurations
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Modify config before request is sent (e.g., add authorization token)
    const token = localStorage.getItem("token"); // Assuming you're storing the token in local storage
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
// Response interceptor to handle responses and errors
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Any status code within the range of 2xx causes this function to trigger
    return response;
  },
  (error: AxiosError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response) {
      // Server responded with a status other than 200 range
      // Handle specific status codes
      if (error.response.status === 401) {
        // Unauthorized
        // Optionally redirect to login
      }
    } else if (error.code === "ERR_NETWORK") {
      toast.error("Please check your internet connection");
    } else if (error.code === "ECONNABORTED") {
      toast.error("Server not responding");
    } else if (error.request) {
      // Request was made but no response received
      console.error("Request error:", error.request);
    } else {
      // Something else happened
      console.error("Error:", error.message);
    }
    return Promise.reject(error.message);
  }
);

// Common method for GET requests
const GET = <T>(
  url: string,
  params: Record<string, unknown> = {},
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  return axiosInstance.get<T>(url, {
    ...config,
    params,
  });
};

// Common method for POST requests
const POST = <T>(
  url: string,
  data: unknown = {},
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  return axiosInstance.post<T>(url, data, config);
};

// Common method for PUT requests
const PUT = <T>(
  url: string,
  data: unknown = {},
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  return axiosInstance.put<T>(url, data, config);
};

// Common method for DELETE requests
const DEL = <T>(
  url: string,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  return axiosInstance.delete<T>(url, config);
};

const api = {
  GET,
  POST,
  PUT,
  DELETE: DEL, // 'delete' is a reserved word in JavaScript
};

export default api;
