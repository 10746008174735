import {
  BuildingStorefrontIcon,
  PlusCircleIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import {
  openModal,
  setLoading,
  stopRefetch,
} from "../../redux/reducers/uiReducer";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import PrimaryButton from "../../components/atoms/PrimaryButton";
import InputField from "../../components/atoms/InputField";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "../../helpers/api";
import { IAddress } from "../../types/CommonType";
import { IResponse, useAuth } from "../../providers/AuthContext";
import toast from "react-hot-toast";
import useUI from "../../redux/hooks/useUi";
import { setUserAddress } from "../../redux/reducers/userAddressReducer";
import { setFormMode } from "../../redux/reducers/formModeReducer";
import { times } from "lodash";
import setFormErrors from "../../helpers/setFormErrors";
import { REGEX } from "../../constants/RegexConstants";

interface UserProfileProps {}

export interface IEditUserDetails {
  name: string;
  phone_number: string;
}

const UserProfile: React.FC<UserProfileProps> = () => {
  const [openEdit, setOpenEdit] = useState(false);
  const { auth, refreshUser } = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<IEditUserDetails>();

  const onSubmit: SubmitHandler<IEditUserDetails> = (data) => {
    api
      .POST(`profile`, data)
      .then((res) => {
        if (res) {
          setOpenEdit(false);
          refreshUser();
        } else {
          // @ts-ignore
          setFormErrors<IEditUserDetails>(res?.data?.message, setError);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div className="flex-1 flex flex-col gap-6 p-2 bg-white w-full">
      <div className="flex flex-col gap-4 p-6 rounded-xl bg-[#F9FAFB] shadow">
        {openEdit ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col md:justify-center items-start md:px-4"
          >
            <p className="text-xl text-center font-semibold leading-6 text-gray-600 mb-4">
              Edit Details
            </p>
            <div className="flex flex-col w-full items-center justify-center gap-2">
              <InputField
                label="Name *"
                placeholder="Enter your name"
                type="text"
                className="text-black border p-2 rounded-md text-xs md:text-sm font-normal w-full"
                error={errors?.name?.message}
                register={register("name", {
                  required: "This Field is Required",
                })}
              />
              <InputField
                label="Phone Number *"
                placeholder="Enter your phone number"
                type="text"
                className="text-black border p-2 rounded-md text-xs md:text-sm font-normal w-full"
                error={errors?.phone_number?.message}
                register={register("phone_number", {
                  required: "This Field is Required",
                  pattern: {
                    value: REGEX.phoneNumber,
                    message: "Invalid Phone Number",
                  },
                })}
              />
              <div className="flex justify-between items-center text-sm w-full mt-2">
                <p className="font-medium">Email</p>
                <p>{auth?.user?.email}</p>
              </div>
            </div>
            <div className="md:self-end w-full md:w-full mt-4 flex justify-between items-center">
              <button
                className="py-2 px-5 text-sm font-medium text-black bg-gray-50 rounded-xl hover:bg-gray-200 inline-flex items-center border border-gray-300"
                onClick={() => {
                  setOpenEdit(false);
                  reset();
                }}
              >
                Cancel
              </button>

              <PrimaryButton
                type="submit"
                btnClassName="w-fit text-center flex items-center justify-center rounded-[6px] h-[38px]"
              >
                Update
              </PrimaryButton>
            </div>
          </form>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <p className="text-gray-600 font-semibold text-base">
                Personal Info
              </p>
              <button
                className="py-1.5 px-3 text-sm font-medium text-white bg-geovetraGreen-500 rounded-xl hover:bg-geovetraGreen-600 inline-flex items-center"
                onClick={() => {
                  reset(auth?.user);
                  setOpenEdit(true);
                }}
              >
                Edit
              </button>
            </div>
            <div className="flex justify-between items-center text-sm">
              <p>Full Name</p>
              <p>{auth?.user?.name}</p>
            </div>
            <div className="flex justify-between items-center text-sm">
              <p>Phone</p>
              <p>{auth?.user?.phone_number}</p>
            </div>
            <div className="flex justify-between items-center text-sm">
              <p>Email</p>
              <p>{auth?.user?.email}</p>
            </div>
          </>
        )}
      </div>
      <AddressCard />
    </div>
  );
};

export default UserProfile;

const AddressCard = () => {
  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState<IAddress[]>();
  const { auth } = useAuth();
  const { refetch } = useUI();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAddresses = () => {
    setLoading(true);
    api
      .GET<IResponse<IAddress[]>>(`/user-addresses`, {
        populate: JSON.stringify([
          "pincode",
          "user",
          "city",
          "state",
          "country",
        ]),
      })
      .then((response) => {
        const adsData = response?.data?.data;
        if (adsData) {
          setAddresses(adsData);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(stopRefetch("AddressList"));
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  useEffect(() => {
    if (refetch.includes("AddressList")) {
      fetchAddresses();
    }
  }, [refetch]);

  const renderSkeleton = () => (
    <div className="flex bg-white flex-col items-start border w-full gap-4 rounded-xl p-6 cursor-default animate-pulse">
      <div className="flex flex-row justify-between items-center w-full">
        <div className="w-12 h-12 rounded-xl bg-red-200"></div>
        <div className="flex flex-col justify-end items-end">
          <div className="h-4 bg-gray-200 rounded w-32 mb-1"></div>
          <div className="h-4 w-20 bg-blue-300 rounded-xl"></div>
        </div>
      </div>
      <div className="flex items-center w-full justify-between">
        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      </div>
    </div>
  );

  return (
    <div className="px-2 py-4 md:p-6 rounded-xl bg-[#F9FAFB] shadow w-full">
      <p className="text-gray-600 font-semibold text-base mb-4">
        Office Address Details
      </p>
      <div className="flex flex-col w-full md:flex-row gap-4 md:grid md:grid-cols-3 justify-center items-center overflow-auto h-[40vh]">
        {loading ? (
          times(3).map((i) => renderSkeleton())
        ) : (
          <>
            {addresses &&
              addresses?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex bg-white flex-col items-start border min-w-screen gap-4 rounded-xl p-6 cursor-default"
                  >
                    <div className="flex justify-between items-center w-full">
                      <div className="p-2 rounded-xl bg-red-100">
                        <BuildingStorefrontIcon className="size-6 text-red-600" />
                      </div>
                      <div className="flex flex-col justify-end items-end">
                        <p className="text-sm mb-1 font-semibold">
                          {auth?.user?.name}
                        </p>
                        <div className="bg-blue-100 p-1 px-2 rounded-xl w-fit">
                          <p className="text-xs text-end text-blue-600 font-medium">
                            {auth?.user?.phone_number}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center w-full justify-between">
                      <p
                        className={`text-xs font-semibold text-gray-500 line-clamp-2 flex-1`}
                      >
                        {item.address_line_1}{" "}
                        {item.address_line_2 ? `, ${item.address_line_2} ` : ""}
                        {item.city?.name ? `, ${item.city?.name}` : ""}
                        {item.state?.name ? `, ${item.state?.name}` : ""}
                        {item.country?.name ? `, ${item.country?.name}` : ""}
                        {item.pincode?.name ? `, ${item.pincode?.name}` : ""}
                      </p>
                      <div className="self-end">
                        <PencilSquareIcon
                          className="size-5 text-black cursor-pointer"
                          onClick={() => {
                            dispatch(setFormMode("update"));
                            dispatch(setUserAddress(item));
                            dispatch(openModal("AddressModal"));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            <div
              className="flex bg-white flex-col items-center border-dashed border-2 min-w-screen gap-4 rounded-xl p-8 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                dispatch(setFormMode("create"));
                dispatch(openModal("AddressModal"));
              }}
            >
              <PlusCircleIcon className="size-8 text-gray-400" />
              <p className="text-base text-gray-400 text-center">
                Create New Address
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
